import React from 'react'
import PropTypes from 'prop-types'

import Typography from '../Typography'
import * as Styled from './Price.styles'

const Price = ({
  large,
  label,
  amount,
  duration,
  suffix,
  children,
  condensed,
  ...props
}) => (
  <Styled.Price condensed={condensed} large={large} {...props}>
    <div
      className="price__wrapper"
      aria-label={`${label || ''} $${amount} per ${duration}`}
    >
      {label && <span className="price__label">{label}</span>}
      <span className="price__currency">$</span>
      <span className="price__dollars">{amount.toString().split('.')[0]}</span>
      <span className="price__cents">
        {amount.toString().split('.')[1] || '00'}
      </span>
      <span className="price__duration">{duration}</span>
      {suffix && <span className="price__suffix">{suffix}</span>}
    </div>
    {children && (
      <Typography variant="legal" rendersAs="div">
        {children}
      </Typography>
    )}
  </Styled.Price>
)

Price.defaultProps = {
  large: false,
  condensed: false,
  label: null,
  currency: '$',
  duration: 'mo',
  suffix: null,
  children: null,
}

Price.propTypes = {
  large: PropTypes.bool,
  condensed: PropTypes.bool,
  label: PropTypes.string,
  currency: PropTypes.string,
  amount: PropTypes.number.isRequired,
  duration: PropTypes.string,
  suffix: PropTypes.string,
  /** Children are rendered out as `Typography` componenents with the legal `variant` prop */
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
}

export default Price
