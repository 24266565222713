import React from 'react'
import PropTypes from 'prop-types'

import Typography from '../Typography'
import Price from '../Price'

const PackageProvider = ({
  title,
  price,
  legal,
  termsLink,
  details,
  bullets,
}) => (
  <>
    {title && (
      <Typography variant="h5" className="title">
        {title}
      </Typography>
    )}
    {details && (
      <div className="details">
        {details.map(detail => (
          <span key={detail}>{detail}</span>
        ))}
      </div>
    )}
    <Price className="price" {...price}>
      {legal && legal}
    </Price>
    {bullets && bullets}
    {termsLink && (
      <a href="/terms" className="terms">
        {termsLink}
      </a>
    )}
  </>
)

PackageProvider.defaultProps = {
  title: '',
  termsLink: '',
  legal: null,
  details: [],
  bullets: [],
}

PackageProvider.propTypes = {
  title: PropTypes.string,
  price: PropTypes.shape({
    label: PropTypes.string,
    amount: PropTypes.number.isRequired,
  }).isRequired,
  legal: PropTypes.node,
  termsLink: PropTypes.string,
  details: PropTypes.arrayOf(PropTypes.string),
  bullets: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.node,
  ]),
}

export default PackageProvider
