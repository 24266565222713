import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'

import * as Styled from './FormCTA.styles'

const FormCTA = forwardRef(
  (
    {
      onSubmit,
      label,
      placeholder,
      buttonText,
      type,
      labelVariant,
      minLength,
      maxLength,
      countries,
      pattern,
    },
    ref
  ) => {
    const validationSchema = {}
    let finalMinLength = minLength
    let finalMaxLength = maxLength
    let finalPattern = pattern

    const zipRegex = {
      US: {
        regex: /(^\d{5}$)/,
        minLength,
        maxLength,
      },
      CAN: {
        regex: /^([A-CEGHJ-NPRSTVXY]\d[A-CEGHJ-NPRSTV-Z] \d[A-CEGHJ-NPRSTV-Z]\d)$/,
        minLength: 7,
        maxLength: 7,
      },
      US_CAN: {
        regex: /^(\d{5}|[A-CEGHJ-NPRSTVXY]\d[A-CEGHJ-NPRSTV-Z] \d[A-CEGHJ-NPRSTV-Z]\d)$/,
        minLength: 5,
        maxLength: 7,
      },
    }

    // If there's no custom pattern then we use the patterns and lengths from zipRegex
    if (!pattern) {
      if (countries.includes('US') && countries.includes('CAN')) {
        finalPattern = zipRegex.US_CAN.regex
        finalMinLength = zipRegex.US_CAN.minLength
        finalMaxLength = zipRegex.US_CAN.maxLength
      } else if (countries.includes('CAN')) {
        finalPattern = zipRegex.CAN.regex
        finalMinLength = zipRegex.CAN.minLength
        finalMaxLength = zipRegex.CAN.maxLength
      } else {
        finalPattern = zipRegex.US.regex
        finalMinLength = zipRegex.US.minLength
        finalMaxLength = zipRegex.US.maxLength
      }
    } else {
      finalPattern = new RegExp(pattern)
    }

    if (type === 'email') {
      validationSchema.email = Yup.string()
        .email()
        .required()
    } else {
      validationSchema.zip = Yup.string()
        .min(finalMinLength, 'Postal Code is not long enough')
        .max(finalMaxLength, 'Postal Code is too long')
        .matches(finalPattern, 'Must be a valid Postal Code')
        .required()
    }

    return (
      <Formik
        initialValues={{ [type]: '' }}
        onSubmit={(_, actions) => {
          actions.setSubmitting(false)
        }}
        validationSchema={Yup.object().shape(validationSchema)}
        ref={ref}
        render={({
          values,
          touched,
          errors,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => {
          const hasError = touched[type] && errors[type]
          return (
            <form onSubmit={onSubmit || handleSubmit}>
              {label && (
                <Styled.Label
                  variant={labelVariant}
                  rendersAs="label"
                  htmlFor={type}
                >
                  {label}
                </Styled.Label>
              )}
              <Styled.Group>
                <Styled.InputWrapper>
                  <Styled.Input
                    id={type}
                    name={type}
                    label={label}
                    placeholder={placeholder}
                    value={values[type]}
                    type={type === 'email' ? 'email' : 'text'}
                    touched={touched[type]}
                    hasError={hasError}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {hasError && (
                    <Styled.Message hasError={hasError}>
                      {errors[type]}
                    </Styled.Message>
                  )}
                </Styled.InputWrapper>
                <Styled.Button type="submit">{buttonText}</Styled.Button>
              </Styled.Group>
            </form>
          )
        }}
      />
    )
  }
)

FormCTA.defaultProps = {
  type: 'zip',
  buttonText: 'Submit',
  placeholder: null,
  label: null,
  onSubmit: null,
  labelVariant: 'feature',
  minLength: 5,
  maxLength: 5,
  countries: ['US'],
  pattern: '',
}

FormCTA.propTypes = {
  type: PropTypes.oneOf(['email', 'zip']),
  buttonText: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onSubmit: PropTypes.func,
  labelVariant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'small',
    'feature',
  ]),
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  countries: PropTypes.arrayOf(PropTypes.oneOf(['US', 'CAN'])),
  pattern: PropTypes.string,
}

export default FormCTA
