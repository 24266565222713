import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import _get from 'lodash.get'
import axios from 'axios'
import ngConfig from '../../static/assets/config/config.json'

// eslint-disable-next-line import/no-named-default
import { default as CoreFormCTA } from './FormCTA/index'
import get from 'lodash.get'
import { gaEventTracker, getPageTitle } from './GaEventTrackerHelper'

const FormCTA = ({ affiliateID, ...props }) => {
  const inputRef = useRef()
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          defaultPromo
        }
      }
    }
  `)
  const defaultPromo = _get(data, 'site.siteMetadata.defaultPromo')
  let promo = affiliateID || defaultPromo
  const JEHandleSubmit = async e => {
    const pageTitle = getPageTitle();
    gaEventTracker("See Plans - CTA", "Click", pageTitle);
    e.preventDefault()
    const url = '/for-homes#/enrollment'

    const zip = _get(inputRef, 'current.state.values.zip')
    if (zip === '') {
      window.location = url
      return
    }

    //Using this proxyUrl to resolve CORS error in Firefox browser
    const proxyUrl = 'https://elb.justenergy.com/';
    const getUrl = `https://mapi.clearlink.com/postal_code/${zip}`;

    await axios.get(proxyUrl + getUrl)
      .then(function (response) {
        console.log(response.data);
        console.log(response.headers);
        console.log("Status code: " + response.status);

        const postalCodes = _get(response, 'data.data.postalCodes')
        const { country, province } = postalCodes[0]
          ? postalCodes[0]
          : { country: '', province: '' }

        // If we didn't find a country or province for the postal code, just go to the enrollment page.
        if (country === '' || province === '') {
          window.location = url
          return
        }

        // If the page doesn't have its own affiliate code, use a Texas-specific code or fallback to 'DEFAULT'.
        if (promo === defaultPromo) {
          promo = province === 'TX' ? 'JUSTWEB' : 'DEFAULT'
        }
        const encodedZip = encodeURIComponent(zip)

        var brand = `${country}/${province}`

        // check in angular config file to see if the brand the postal code belongs to falls under NotSupportedBrands (brands with online enrollment on another site)
        // if so then we want to redirect the the specified url
        var nsBrand = ngConfig.NotSupportedBrands.find(b => b.Brand === brand)
        if (nsBrand) {
          window.location = nsBrand.URL;
        } else {
          // check in angular config file to see if the brand the postal code belongs to falls under   (brands without online enrollment)
          // if so then we want to redirect to to a valid brand so proper message can be displayed
          var nspBrand = ngConfig.BrandsWithNotSupportedPortal.find(b => b.Brand === brand)
          if (nspBrand) {
            brand = ngConfig.Brands[0].Brand;
          } else {
            // check in angular config file to see if brand is supported - if not then it's not a brand JE supports in any way
            // if so then we want to redirect to to a valid brand so proper message can be displayed
            var supportedBrand = ngConfig.Brands.find(b => b.Brand === brand)
            if (!supportedBrand) {
              brand = ngConfig.Brands[0].Brand;
            }
          }

          if (brand === "US/TX") {
            window.location = `/residential-plans#/enrollment/${brand}/SVC/residential-plans?promoCode=${promo}&postalCode=${encodedZip}`
          } else {
            window.location = `/for-homes#/enrollment/${brand}/SVC?promoCode=${promo}&postalCode=${encodedZip}`
          }

          //window.location = `/for-homes#/enrollment/${brand}/SVC?promoCode=${promo}&postalCode=${encodedZip}`
        }

      })
      .catch(function (error) {
        gaEventTracker("API", "Fail", "Mapi.clearlink");
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log("Error: ", error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log("Error: ", error.config);
      });



  }

  return (
    <CoreFormCTA
      {...props}
      onSubmit={JEHandleSubmit}
      ref={inputRef}
      countries={['US', 'CAN']}
    />
  )
}

FormCTA.propTypes = {
  affiliateID: PropTypes.string.isRequired,
}

export default FormCTA
