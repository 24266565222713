import React from 'react'
import PropTypes from 'prop-types'
import { Lens } from 'emotion-icons/material'

import * as Styled from './List.styles'

const ListItem = ({ icon, content, children, ...props }) => (
  <Styled.ListItem icon={icon && true} {...props}>
    {icon && icon}
    {content && <div>{content}</div>}
    {children && <div>{children}</div>}
  </Styled.ListItem>
)

ListItem.defaultProps = {
  icon: <Lens size="0.5em" />,
  content: null,
  children: null,
}

ListItem.propTypes = {
  icon: PropTypes.element,
  content: PropTypes.string,
  children: PropTypes.element,
}

export default ListItem
