import React from 'react'
import Link from '../components/Link'
import Button from '../components/Button'
import PhoneCTA from '../components/PhoneCTA'
import FormCTA from '../components/FormCTA'
import Placeholder from '../components/Placeholder'
import Typography from '../components/Typography'
import { gaEventTracker, getPageTitle } from '../components/GaEventTrackerHelper'

export default (cta, passedFrom) => {
  const { type } = cta

  const inHero = passedFrom === 'fromHero'

  let phoneNumberCTA = false;
  const pageTitle = getPageTitle();

  switch (type) {
    case 'Link': {
      const { destinationURL, buttonText } = cta
      if (destinationURL === "tel:1-866-288-3105") {
        phoneNumberCTA = true;
      }
      return (
        <Link to={destinationURL}>
          <Button variant={inHero ? 'hero' : 'default'} onClick={phoneNumberCTA ? () => gaEventTracker("TFN - CTA", "Click", pageTitle) : null}>{buttonText}</Button>
        </Link>
      )
    }

    case 'Phone': {
      const { buttonText } = cta
      return (
        <PhoneCTA variant={inHero ? 'hero' : 'default'}>
          {buttonText && buttonText}
        </PhoneCTA>
      )
    }

    case 'Zip': {
      const { labelText, buttonText, placeholderText } = cta
      return (
        <FormCTA
          label={labelText}
          buttonText={buttonText}
          placeholder={placeholderText}
          labelVariant={inHero ? 'h5' : 'feature'}
        />
      )
    }

    default: {
      return (
        <Placeholder type="warning">
          <Typography variant="small">Missing Component</Typography>
          <Typography>
            The {type['en-US']} component can&apos;t be rendered here. It is
            most likely missing support from the core.
          </Typography>
        </Placeholder>
      )
    }
  }
}
