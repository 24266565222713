import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { rem } from 'polished'

import Typography from '../Typography'
import CoreInput from '../Form/Input'
import CoreButton from '../Button'

export const Label = styled(Typography)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing.xxs};
  `
)

// inline-flex because the parent holding the div might have centered content
export const Group = styled.div`
  display: inline-flex;
  align-items: stretch;
`

export const InputWrapper = styled.div`
  position: relative;
`

export const Input = styled(CoreInput)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 100%;
`

export const Message = styled.div`
  font-size: ${({ theme }) => rem(theme.fonts.body.xs.size)};
  color: ${({ theme, hasError }) =>
    hasError ? theme.colors.error.base.hex : 'inherit'};
  position: absolute;
`

export const Button = styled(CoreButton)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`
