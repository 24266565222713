import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { rem, em } from 'polished'

// TODO: Refactor this from using theme.fonts to just being EM based. FFS...
export const Price = styled.div(({ theme, condensed, large }) => {
  let baseFontSize
  if (condensed) {
    baseFontSize = large
      ? theme.fonts.headings.md.size[1] * 2
      : theme.fonts.headings.md.size[0] * 2
  } else {
    baseFontSize = large
      ? theme.fonts.headings.lg.size[1]
      : theme.fonts.headings.md.size[1]
  }

  return css`
    max-width: 245px;
    font-size: ${rem(baseFontSize)};

    .price {
      &__wrapper {
        font-weight: 600;

        &::before,
        &::after {
          content: '';
          display: table;
        }

        &::after {
          clear: both;
        }
      }

      &__label {
        display: block;
        margin-bottom: 0.5em;
        font-size: ${em(theme.fonts.body.sm.size, baseFontSize)};
        font-weight: 400;
      }

      &__currency {
        float: left;
        margin-right: ${theme.spacing.min};
        font-size: ${em(theme.fonts.subheadings.md.size[1], baseFontSize)};
      }

      &__dollars {
        float: left;
        line-height: ${condensed ? 0.7 : 1};
      }

      &__cents {
        float: ${condensed ? 'none' : 'left'};
        display: ${condensed ? 'block' : 'inline'};
        font-size: ${condensed
          ? em(theme.fonts.subheadings.md.size[1], baseFontSize)
          : '1em'};
        line-height: 1;

        &::before {
          display: ${condensed ? 'none' : 'inline-block'};
          content: '.';
        }
      }

      &__duration {
        float: left;
        margin-left: ${theme.spacing.min};
        font-size: ${em(theme.fonts.subheadings.md.size[1], baseFontSize)};
        font-weight: ${condensed ? 400 : 600};

        &::before {
          content: '/';
        }
      }

      &__suffix {
        display: block;
        font-size: ${em(theme.fonts.body.sm.size, baseFontSize)};
        font-weight: 400;
        clear: left;
      }
    }
  `
})
