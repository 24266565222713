import React from 'react'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import { Done } from 'emotion-icons/material'
import lodashGet from 'lodash.get'
import uuid from 'uuid/v4'

import Typography from '../components/Typography'
import List from '../components/List'
import ListItem from '../components/List/ListItem'
import PackageProvider from '../components/PackageProvider'
import Placeholder from '../components/Placeholder'
import Table from '../components/Table'
import Button from '../components/Button'
import Link from '../components/Link'
import determineCTA from './determineCTA'

const getPlaceholder = contentType => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line
    console.error(
      'richTextBlockMap encountered an error when trying to map an embedded entry. This method is part of `gatsby-theme-content`.'
    )
  }

  const missingDataErrorMessage = `The data for this component cannot be fetched without a clean rebuild`
  const defaultMessage = `The ${contentType} component can't be rendered here. It is
          most likely missing support from the core.`
  return (
    <Placeholder type={contentType ? 'warning' : 'error'}>
      <Typography variant="small">
        {contentType ? 'Missing Component' : 'Error'}
      </Typography>
      <Typography>
        {contentType ? defaultMessage : missingDataErrorMessage}
      </Typography>
    </Placeholder>
  )
}

const mapDefaults = {
  renderNode: {
    [BLOCKS.HEADING_1]: node =>
      node.content.map(content => (
        <Typography key={`${content.value}-key`} variant="h1">
          {content.value}
        </Typography>
      )),
    [BLOCKS.HEADING_2]: node =>
      node.content.map(content => (
        <Typography key={`${content.value}-key`} variant="h2">
          {content.value}
        </Typography>
      )),
    [BLOCKS.HEADING_3]: node =>
      node.content.map(content => (
        <Typography key={`${content.value}-key`} variant="h3">
          {content.value}
        </Typography>
      )),
    [BLOCKS.HEADING_4]: node =>
      node.content.map(content => (
        <Typography key={`${content.value}-key`} variant="h4">
          {content.value}
        </Typography>
      )),
    [BLOCKS.PARAGRAPH]: (node, children) => <Typography>{children}</Typography>,
    [BLOCKS.UL_LIST]: (node, children) => <List>{children}</List>,
    [BLOCKS.OL_LIST]: (node, children) => (
      <List type="ordered">{children}</List>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const { fields } = node.data.target
      const contentType = lodashGet(node, 'data.target.sys.contentType.sys.id')

      switch (contentType) {
        case 'disclaimer': {
          return fields.mainContent['en-US'].content[0].content.map(item => (
            <Typography variant="legal">{item.value}</Typography>
          ))
        }
        case 'cta': {
          const {
            type,
            buttonText,
            labelText,
            destinationURL,
            placeholderText,
          } = fields
          const data = {
            type: type['en-US'],
            buttonText: buttonText ? buttonText['en-US'] : null,
            labelText: labelText ? labelText['en-US'] : null,
            destinationURL: destinationURL ? destinationURL['en-US'] : null,
            placeholderText: placeholderText ? placeholderText['en-US'] : null,
          }

          return determineCTA(data)
        }
        case 'document': {
          const Wrapper = fields.buttonStyle['en-US'] ? Button : React.Fragment
          return (
            <a
              href={fields.file['en-US'].fields.file['en-US'].url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Wrapper>{fields.linkText['en-US']}</Wrapper>
            </a>
          )
        }
        case 'package': {
          const legal = fields.disclaimer['en-US'].content[0].content.map(
            item => <Typography variant="legal">{item.value}</Typography>
          )

          return (
            <PackageProvider
              price={{
                label: fields.prefix ? fields.prefix['en-US'] : null,
                amount: fields.price['en-US'],
                suffix: fields.suffix ? fields.suffix['en-US'] : null,
              }}
              legal={legal}
            />
          )
        }
        case 'basicTable': {
          return <Table json={fields.tableData['en-US']} />
        }
        default:
          return getPlaceholder(contentType)
      }
    },
    [INLINES.HYPERLINK]: (node, children) => (
      <Link to={node.data.uri}>{children}</Link>
    ),
    [INLINES.EMBEDDED_ENTRY]: node => {
      const { fields } = node.data.target
      const contentType = lodashGet(node, 'data.target.sys.contentType.sys.id')

      switch (contentType) {
        case 'disclaimer': {
          return fields.mainContent['en-US'].content[0].content.map(item => (
            <Typography variant="legal">{item.value}</Typography>
          ))
        }
        default: {
          return getPlaceholder(contentType)
        }
      }
    },
  },
  renderMarks: {
    [MARKS.BOLD]: text => <strong>{text}</strong>,
    [MARKS.ITALIC]: text => <em>{text}</em>,
  },
  // This came from Contentful's docs on how to handle making new lines <br /> tags
  renderText: text =>
    text
      .split('\n')
      .reduce(
        (children, textSegment, index) => [
          ...children,
          index > 0 && <br key={uuid()} />,
          textSegment,
        ],
        []
      ),
}

export const richTextHeroMap = {
  ...mapDefaults,
  renderNode: {
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <ListItem icon={<Done />}>
        <Typography variant="h5" rendersAs="span">
          {children}
        </Typography>
      </ListItem>
    ),
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const { fields } = node.data.target
      const contentType = lodashGet(node, 'data.target.sys.contentType.sys.id')

      switch (contentType) {
        case 'disclaimer': {
          return fields.mainContent['en-US'].content[0].content.map(item => (
            <Typography variant="legal">{item.value}</Typography>
          ))
        }
        case 'cta': {
          const {
            type,
            buttonText,
            labelText,
            destinationURL,
            placeholderText,
          } = fields
          const data = {
            type: type['en-US'],
            buttonText: buttonText ? buttonText['en-US'] : null,
            labelText: labelText ? labelText['en-US'] : null,
            destinationURL: destinationURL ? destinationURL['en-US'] : null,
            placeholderText: placeholderText ? placeholderText['en-US'] : null,
          }

          return determineCTA(data, 'fromHero')
        }
        default: {
          return getPlaceholder(contentType)
        }
      }
    },
    [INLINES.HYPERLINK]: (node, children) => (
      <Link to={node.data.uri}>{children}</Link>
    ),
  },
}

export const richTextLegalMap = {
  renderNode: {
    [BLOCKS.TEXT]: (node, children) => (
      <Typography variant="legal">{children}</Typography>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Typography variant="legal">{children}</Typography>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <Link to={node.data.uri}>{children}</Link>
    ),
  },
}

// TODO: Probably don't need this anymore. It was created with the local page test stuff
export const richTextMapToString = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => children,
    [INLINES.EMBEDDED_ENTRY]: node => {
      const { fields } = node.data.target
      const contentType = lodashGet(node, 'data.target.sys.contentType.sys.id')

      switch (contentType) {
        case 'replaceText': {
          return fields.name['en-US']
        }
        default: {
          return null
        }
      }
    },
  },
}

export default mapDefaults
